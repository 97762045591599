import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { SubLink, StyledLink, StyledSubMenu } from "./submenuStyles";

const SubMenu = ({ lists, showSubMenu, mobile }) => {
    if (!lists) return null;

    const items = lists
        .filter(({ onlyMobile }) => (!mobile ? !onlyMobile : true))
        .map((list, index) => {
            const { title, to } = list;
            return (
                <SubLink key={title} count={index}>
                    <StyledLink
                        to={to}
                        title={title}
                        onMouseOut={e => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    >
                        {title}
                    </StyledLink>
                </SubLink>
            );
        });

    return <StyledSubMenu show={showSubMenu}>{items}</StyledSubMenu>;
};

export default SubMenu;
