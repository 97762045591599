import React from "react";
import {
    FooterContainer,
    FooterBox,
    Row,
    ColLeft,
    ColCenter,
    ColRight,
    Copyright,
    SocialRow,
    LogoImgContainer,
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    YoutubeIcon,
    StyledIconA,
    StyledLink,
    StyledAnchor,
    InfoContainer,
    InfoBox,
    InfoText,
    SiteMapBox,
    SiteInfo,
    AddressText,
} from "./styles";
import KccauLogo from "./Images";
import MailChimp from "../mailchimp";

const Footer = () => {
    return (
        <FooterContainer>
            <MailChimp />
            <FooterBox>
                <Row>
                    <ColLeft>
                        <SocialRow>
                            <StyledIconA
                                href="https://www.facebook.com/koreanfilmfestival"
                                target="_blank"
                            >
                                <FacebookIcon />
                            </StyledIconA>
                            <StyledIconA
                                href="https://www.instagram.com/koreanfilmfestival/"
                                target="_blank"
                            >
                                <InstagramIcon />
                            </StyledIconA>
                            <StyledIconA
                                href="https://twitter.com/koffiafilmfest"
                                target="_blank"
                            >
                                <TwitterIcon />
                            </StyledIconA>
                            <StyledIconA
                                href="https://www.youtube.com/koreanfilmfestival"
                                target="_blank"
                            >
                                <YoutubeIcon />
                            </StyledIconA>
                        </SocialRow>
                        <div>
                            <StyledAnchor
                                href="http://www.koreanculture.org.au"
                                target="_blank"
                            >
                                <LogoImgContainer>
                                    <KccauLogo />
                                </LogoImgContainer>
                            </StyledAnchor>
                        </div>
                    </ColLeft>
                    <ColCenter>
                        <SiteMapBox>
                            <SiteInfo>
                                <StyledLink to="/">Home</StyledLink>
                            </SiteInfo>
                            <SiteInfo>
                                <StyledLink to="/films">
                                    Film Line-Up
                                </StyledLink>
                            </SiteInfo>
                            <SiteInfo>
                                <StyledLink to="/touring/alice-springs">
                                    Alice Springs
                                </StyledLink>
                            </SiteInfo>
                            <SiteInfo>
                                <StyledLink to="/about">About</StyledLink>
                            </SiteInfo>
                            <SiteInfo>
                                <StyledLink to="/schedule">Schedule</StyledLink>
                            </SiteInfo>
                            <SiteInfo>
                                <StyledLink to="/touring/benalla">
                                    Benalla
                                </StyledLink>
                            </SiteInfo>
                            <SiteInfo>
                                <StyledLink to="/history">History</StyledLink>
                            </SiteInfo>
                            <SiteInfo>
                                <StyledLink to="/festival-guests">
                                    Festival guests
                                </StyledLink>
                            </SiteInfo>
                            <SiteInfo>
                                <StyledLink to="/touring/burnside">
                                    Burnside
                                </StyledLink>
                            </SiteInfo>
                            <SiteInfo>
                                <StyledLink to="/partners">Partners</StyledLink>
                            </SiteInfo>
                            <SiteInfo>
                                <StyledLink to="/touring/intro">
                                    Touring Program
                                </StyledLink>
                            </SiteInfo>
                            <SiteInfo>
                                <StyledLink to="/touring/canberra">
                                    Canberra
                                </StyledLink>
                            </SiteInfo>
                        </SiteMapBox>
                    </ColCenter>
                    <ColRight>
                        <InfoContainer>
                            <InfoBox>
                                <StyledAnchor href="mailto:info@koffia.com.au">
                                    <InfoText>info@koffia.com.au</InfoText>
                                </StyledAnchor>
                            </InfoBox>
                            <InfoBox>
                                <StyledAnchor
                                    href="https://www.google.com/maps/place/Korean+Cultural+Centre/@-33.8757622,151.2072083,17z/data=!3m1!4b1!4m5!3m4!1s0x6b12ae3d9183e319:0x4a768edc321f45f7!8m2!3d-33.8757622!4d151.2094023"
                                    target="_blank"
                                >
                                    <AddressText>
                                        Ground Floor, 255 Elizabeth St.
                                    </AddressText>
                                    <AddressText> Sydney NSW 2000</AddressText>
                                </StyledAnchor>
                            </InfoBox>
                        </InfoContainer>
                    </ColRight>
                </Row>
                <Row>
                    <Copyright>
                        Copyright &copy; {new Date().getFullYear()} Korean Film
                        Festival In Australia. All rights reserved.
                    </Copyright>
                </Row>
            </FooterBox>
        </FooterContainer>
    );
};

export default Footer;
