import React from "react";
import PropTypes from "prop-types";
import { createGlobalStyle } from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import Header from "../header";
import Footer from "../footer";
import { breakpoints, colors } from "../../styles/variables";

const GlobalStyle = createGlobalStyle`
    body {
        font-family: 'Asap', sans-serif;
        font-size: 16px;
        color: ${colors.dark};
        margin: 0;
        padding: 0;
    }
`;

const Layout = ({ children }) => {
    return (
        <>
            <GlobalStyle />
            <Header />
            <main role="main">{children}</main>
            <Footer />
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
