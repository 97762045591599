export const menus = [
    {
        title: "About",
        id: 0,
        to: "/about",
        submenus: [
            {
                title: "KOFFIA",
                to: "/about",
            },
            {
                title: "History",
                to: "/history",
            },
        ],
    },
    {
        title: "Programs",
        id: 1,
        to: "/schedule",
        submenus: [
            {
                title: "Schedule",
                to: "/schedule",
            },
            {
                title: "Film line-up",
                to: "/films",
            },
            {
                title: "Festival guests",
                to: "/festival-guests",
            },
        ],
    },
    {
        title: "Touring program",
        id: 2,
        to: "/touring/intro",
        submenus: [
            { title: "Intro", to: "/touring/intro" },
            {
                title: "Alice Springs",
                to: "/touring/alice-springs",
            },
            {
                title: "Benalla",
                to: "/touring/benalla",
            },
            {
                title: "Burnside",
                to: "/touring/burnside",
            },
            {
                title: "Canberra",
                to: "/touring/canberra",
            },
        ],
    },
    {
        title: "Partners",
        id: 3,
        to: "/partners",
        submenus: [
            {
                title: "Partners",
                to: "/partners",
            },
        ],
    },
    {
        title: "Contact",
        id: 4,
        to: "/contact",
        submenus: [
            {
                title: "Contact",
                to: "/contact",
            },
        ],
    },
];
