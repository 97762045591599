import React from "react";
import { Link } from "gatsby";
import styled, { keyframes } from "styled-components";
import { colors, breakpoints } from "../../styles/variables";

export const SubLink = styled.li`
    text-decoration: none;
    display: block !important;
    margin-left: -1rem;
    padding-right: 3rem;

    @media (min-width: ${breakpoints.xl}) {
        margin-left: -2rem;

        &:last-child {
            padding-bottom: ${(props: { count: number }) =>
                props.count === 3 ? `0rem;` : `4rem;`};
        }
    }
`;

export const StyledLink = styled(props => <Link {...props} />)`
    text-decoration: none;
    color: #cbcbcb;
    font-size: 0.8rem;
    display: inline-block;
    width: 100%;
    margin-bottom: 0.8rem;

    &:hover {
        color: ${colors.primary};
    }

    @media (min-width: ${breakpoints.xl}) {
        color: #fffs;
        font-size: 1rem;
    }
`;

const slideAnimationY = keyframes`
    from {
        opacity: 0; transform: translateY(-1rem);   
    }
    to {
        opacity: 1; transform: translateY(0); 
    }
`;

export const StyledSubMenu = styled.ul`
    min-width: 4rem;
    position: relative;
    transition: all 0.3s ease-in-out;
    z-index: 2;
    margin-top: 0.5rem;
    width: 100%;
    height: 100%;

    @media (min-width: ${breakpoints.xl}) {
        position: absolute;
        padding-top: 2.75rem;
        animation: ${slideAnimationY} 0.3s ease-in-out;

        ${(props: { show: boolean }) =>
            props.show ? ` display:block;` : ` display:none;`}
    }
`;
